/* eslint-disable @typescript-eslint/no-explicit-any */
import { DTO } from "./DTOInterface";

type TMemberListDTO = {
  items: TMemberDTO[];
  page_size: number;
  page: number;
  total_items: number;
};

export interface TAliasDTO {
  company_name: string;
  slug: string;
  logo_url: any;
  deleted: boolean;
  additional_terms: boolean;
  is_standalone: boolean | null;
}

export interface TMemberPainAreaDTO {
  id: number;
  pain_area_id: string;
  intake_answer_id: number;
  intake_answer: IntakeAnswer;
  status: Status;
  last_response: number;
  stats: Stats;
  current_program: any;
  coach: Coach;
  applicant_notes: any;
  red_flags_count: any;
  potentially_not_a_fit: any;
  not_a_fit: any;
  no_fit_questions: any;
  current_week: number;
  intro_call_date: string;
  time_zone: string;
  not_self_schedule: any;
  second_application: null | boolean;
}

export interface IntakeAnswer {
  id: number;
  creation_timestamp: number;
  current_section: number;
}

export interface Coach {
  id: number;
  user: User;
}

export interface TMemberDTO {
  member_risks: any[];
  id: number;
  utm_object: {};
  terms_accepted: any;
  how_met_us: any;
  automated_emails: any;
  main_pain_area_id: number;
  member_pain_areas: TMemberPainAreaDTO[];
  first_login: boolean;
  meet_link: string | null;
  light_sensitive: any;
  presurgery_program: any;
  user_id: string;
  registration_method: string;
  video_quality: any;
  apero_id: any;
  residence_state: any;
  verified_user: boolean;
  external_id: any;
  automated_sms: any;
  creation_timestamp: any;
  exercise_reminder: {};
  user: User;
  main_pain_area: MainPainArea;
  notifications_paused: any;
  testimonial_submitted: any;
  company_id: any;
  company: TAliasDTO | null;
  relationship: string;
  recommendations: any;
  accidental_signup: boolean;
  alias: TAliasDTO | null;
  intro_call_date: null | string;
  time_zone: string | null;
}

export interface User {
  coach: any;
  id: string;
  phone_number: string;
  language: string;
  gender: string;
  last_name: string;
  first_name: string;
  last_logged_in: number;
  email: string;
  birth_date: any;
  tmp_email: string | null;
}

export interface MainPainArea {
  not_self_schedule: boolean;
  current_program: {
    current_day: number;
    program_days: string[];
    program: { title: string };
  } | null;
  intake_answer: {
    current_section: number | null;
    creation_timestamp: number | null;
    id: number;
  };

  coach: any;
  id: number;
  stats: Stats;
  no_show_intro_call: any;
  notes: string;
  status_history: StatusHistory[];
  intake_part_responded: any;
  status_id: number;
  red_flags_count: any;
  intro_call_event_id: any;
  last_program_position: any;
  time_zone: any;
  intro_call_completed: any;
  intake_completed: boolean;
  accepted_in_limbo_date: any;
  paused_reason: any;
  current_week: number;
  red_flag: any;
  intro_call_channel: any;
  pain_area_id: string;
  application_submitted: number;
  last_response: number;
  coach_id: any;
  member_id: number;
  potentially_not_a_fit: any;
  applicant_notes: any;
  not_a_fit: any;
  intro_call_date: any;
  no_fit_questions: any;
  favorites: any;
  status: Status;
  unpause_date: any;
  start_date: number;
  intake_answer_id: number;
}

export interface Stats {
  lastDate: number;
  lastPain: number;
  firstDate: number;
  firstPain: number;
  currentDate: number;
  currentPain: number;
  firstAnxiety: number;
  firstDepression: number;
  currentSatisfaction: any;
}

export interface StatusHistory {
  id: number;
  status: string;
  member_painarea_id: number;
  modified_date: number;
}

export interface Status {
  id: number;
  status: string;
  member_painarea_id?: number;
  modified_date?: number;
}

export interface TMemberItem {
  key: string;
  id: number | string;
  userId: number | string;
  coachId: number;
  memberPaId: number;
  firstName: string;
  lastName: string;
  lastResponse: number;
  lastLogin: number;
  alias: any;
  introCallDate: string;
  currentPrograms:
    | {
        currentDayIndex?: number;
        title?: string; //member?.main_pain_area.current_program.program.title,
        currentDay?: string;
      }[]
    | [];
  notSelfSchedule: boolean;
  satisfaction: string;
  coach: string;
  phoneNumber: string;
  email: string;
  tmpEmail: string | null;
  stats: Stats;
  status: Status;
  painArea: string;
  language: string;
  registrationMethod: string;
  verifiedUser: boolean;
  redFlagsCount: number;
  noFitQuestions: string[];
  notAFit: boolean;
  potentiallyNotAFit: boolean;
  applicationSubmittedTimestamp: number | null;
  LastResponse: number;
  notes: string;
  CurrentSection: number | null;
  painRating: string;
  mainPainArea: MainPainArea;
  TZ: string | null;
  callLink: string | null;
  secondApplicationPainArea: boolean | null;
}

export interface TMemberList {
  Count: number;
  Page: number;
  PageSize: number;
  Items: TMemberItem[];
}

export class MemberListDTOTransformer implements DTO {
  DTO: TMemberListDTO;
  status: string[];
  constructor(MemberListDTO: TMemberListDTO, status: string) {
    this.DTO = MemberListDTO;
    this.status = status.split(",");
  }

  getOriginalDTO() {
    return this.DTO;
  }

  getModel() {
    const MemberListObject = {
      Count: this.DTO.total_items,
      Page: this.DTO.page,
      PageSize: this.DTO.page_size,
      Items: this.DTO.items.flatMap(member => {
        return MemberListDTOTransformer.convertItem(member, this.status);
      }),
    };
    return MemberListObject;
  }

  static parseAlias(alias: TAliasDTO | null) {
    if (!alias) return null;
    return {
      Slug: alias.slug,
      AdditionalTerms: alias.additional_terms,
      CompanyName: alias.company_name,
      IsStandalone: alias.is_standalone,
    };
  }

  static convertItem(member: TMemberDTO, status: string[]): TMemberItem[] {
    const multiplePaMember = member.member_pain_areas
      ?.filter(mPa => status.includes(mPa.status.status))
      .map(mPA => {
        return {
          key: `${member.user.id}-${mPA.id}`,
          id: member.user.id,
          userId: member.id,
          memberPaId: mPA?.id,
          introCallDate: mPA?.intro_call_date,
          firstName: member.user.first_name,
          lastName: member.user.last_name,
          lastResponse: mPA?.last_response,
          lastLogin: member.user?.last_logged_in,
          alias: MemberListDTOTransformer.parseAlias(member.company),
          currentPrograms: [
            {
              currentDayIndex: mPA?.current_program?.current_day,
              title: mPA.current_program?.program?.title,
              currentDay: mPA.current_program?.program_days[mPA.current_program?.current_day],
            },
          ],
          satisfaction: mPA?.stats?.currentSatisfaction,
          coach: mPA?.coach?.user?.first_name,
          coachId: mPA?.coach?.id,
          mainPainArea: mPA,
          phoneNumber: member.user.phone_number,
          email: member.user.email,
          tmpEmail: member.user.tmp_email,
          stats: mPA?.stats,
          status: mPA?.status,
          painArea: mPA?.pain_area_id,
          language: member.user.language || "en",
          registrationMethod: member.registration_method,
          verifiedUser: member.verified_user,
          redFlagsCount: mPA?.red_flags_count,
          noFitQuestions: mPA?.no_fit_questions,
          notAFit: mPA?.not_a_fit,
          notSelfSchedule: mPA?.not_self_schedule,
          potentiallyNotAFit: mPA?.potentially_not_a_fit,
          secondApplicationPainArea: mPA?.second_application,
          applicationSubmittedTimestamp: mPA?.intake_answer?.creation_timestamp,
          LastResponse: mPA?.last_response,
          notes: mPA?.applicant_notes ?? "",
          CurrentSection: mPA?.intake_answer?.current_section,
          painRating: "None",
          TZ: mPA?.time_zone,
          callLink: member?.meet_link,
        };
      });

    return multiplePaMember as unknown as TMemberItem[];
  }
}
