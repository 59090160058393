/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse, ApisauceInstance } from "apisauce";
import { Header } from "../../constants";
import { trackEvent } from "../../services/analytics";
import axios, { CancelToken, CancelTokenSource } from "axios";

export interface ApiInstance {
  sauceApi: ApisauceInstance;
  service: string;
  header: Object;
}

type RequestType = () => Promise<ApiResponse<unknown, unknown>>;

class Api {
  sauceApi: ApisauceInstance;
  service: string;
  header: Object;
  cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  constructor(SauceInstance, service, header) {
    this.sauceApi = SauceInstance;
    this.service = service;
    this.header = header;
  }

  createCancelToken() {
    this.cancelTokenSource = axios.CancelToken.source();
    return this.cancelTokenSource;
  }

  cancelRequest() {
    return new Promise<void>((resolve, reject) => {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Request canceled by the user.");
        resolve(); // Resolve the promise once the cancellation is triggered
      } else {
        reject(new Error("No cancel token source available"));
      }
    });
  }

  addService(path: string): void {
    this.service = path;
  }

  getHeaders() {
    return this.header;
  }

  setHeaders(header: Header): void {
    this.sauceApi.setHeaders(header);
  }

  async doRequestWithRetry(request: RequestType, path) {
    const maxRetries = 3;
    const slotTime = 500;
    let retryCount = 0;
    do {
      try {
        const res = await request();
        if (!res || !res?.ok) {
          throw res;
        }
        return res;
      } catch (error) {
        const isLastRequest = retryCount === maxRetries;
        trackEvent("Web.MSK.Api.ErrorSendingRequest", { message: `Error sending request, requests ${retryCount}` });
        if (isLastRequest) {
          trackEvent("Web.MSK.Api.ErrorObject", { message: `Error sending request, error ${JSON.stringify(error)}`, path });
          console.error(error);
          return Promise.reject(error);
        }
      }
      const randomTime = Math.floor(Math.random() * slotTime);
      const delay = 2 ** retryCount * slotTime + randomTime;
      // Wait for the exponentially increasing delay period before
      // retrying again.
      await new Promise(resolve => setTimeout(resolve, delay));
    } while (retryCount++ < maxRetries);
  }

  async get(query?: string, params?: Record<string, string>, config?: Object): Promise<any> {
    const param = query ? `${this.service}/${query}` : this.service;
    const cancelToken: CancelToken = this.createCancelToken().token;
    return this.doRequestWithRetry(() => this.sauceApi.get(param, params, { ...config, cancelToken }), param);
  }

  async post(query: Object | undefined, ...args: any[]): Promise<any> {
    const param = args[0] ? `${this.service}/${args[0]}` : this.service;
    return this.doRequestWithRetry(() => this.sauceApi.post(param, query), param);
  }

  async patch(id: number, query: Object | undefined): Promise<any> {
    return this.doRequestWithRetry(() => this.sauceApi.patch(`${this.service}/${id}`, query), `${this.service}/${id}`);
  }

  async put(id: string, query: Object | undefined): Promise<any> {
    return await this.doRequestWithRetry(() => this.sauceApi.put(`${this.service}/${id}`, query), `${this.service}/${id}`);
  }

  async delete(id: string): Promise<any> {
    return this.doRequestWithRetry(() => this.sauceApi.delete(`${this.service}/${id}`), `${this.service}/${id}`);
  }
}

export { Api };
